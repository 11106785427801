<script lang="tsx">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'AppCategory',
})
</script>
<template>
  <div class="bloom-category">
    <Cascader
      v-model:value="localValue"
      placeholder="请选择分类"
      :maxTagCount="maxTagCount"
      :fieldNames="fieldNames"
      @change="handleChange"
      :multiple="multiple"
      :show-checked-strategy="Cascader.SHOW_CHILD"
      class="wandao"
      v-bind="$attrs"
      :options="options"
    />
  </div>
</template>
<script lang="tsx" setup name="AppCategory">
  import { ref } from 'vue'
  import { getCategoryTreeList } from '@admin/api/common/common'
  import { Cascader } from 'ant-design-vue-beta3'
  import { getMaterialCategoryList } from '@admin/api/plm/materialManagement/materialClassification'

  const createEndsWithRegex = (endStr) => {
    const escaped = endStr.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
    return new RegExp(escaped + '$')
  }
  const props = defineProps({
    value: {
      type: [String, Array],
    },
    scene: {
      type: String,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    maxTagCount: {
      type: Number,
      default: 3,
    },
  })
  const emit = defineEmits(['update:value', 'change'])

  const options = ref<any>([])
  const leafMap = ref({})
  const getOptions = async () => {
    const api = props.scene === 'material' ? getMaterialCategoryList : getCategoryTreeList
    const { data } = await api({ status: 1 })
    options.value = data
    getLeafMap(data)
  }

  //{key:value}
  // key: 一级code,二级code,...末级code
  // value: 一级label=>二级label=>...末级label

  const getLeafMap = (options = []) => {
    options.forEach((option) => traverse(option))
  }
  const traverse = (option: any = {}, pLabel = '', pValue = '') => {
    const label = option[unref(fieldNames).label]
    const value = option[unref(fieldNames).value]
    const children = option.children || []

    const leafLabel = pLabel ? `${pLabel}=>${label}` : label
    const leafValue = pValue ? `${pValue},${value}` : value
    if (children?.length > 0) {
      children.forEach((child) => traverse(child, leafLabel, leafValue))
    } else {
      leafMap.value[leafValue] = leafLabel
    }
  }
  const fieldNames = computed(() => {
    if (props.scene === 'meterial') {
      return {
        label: 'material_category_name',
        value: 'material_category_code',
      }
    } else {
      return {
        label: 'category_name',
        value: 'category_code',
      }
    }
  })

  const localValue = ref<any>([])

  watch(
    () => [props.value, leafMap],
    ([newValue]) => {
      if (typeof newValue === 'string' || Array.isArray(newValue)) {
        const values = Array.isArray(newValue) ? newValue : [newValue]
        localValue.value = values.reduce((acc: any, cur) => {
          const reg = createEndsWithRegex(cur)
          const targetKey = Object.keys(unref(leafMap)).find((key) => reg.test(key))
          const target = targetKey?.split(',')
          if (props.multiple) {
            acc.push(target)
            return acc
          } else {
            return target
          }
        }, [])
      }
    },
    { deep: true },
  )

  const handleChange = (value, selectedOptions) => {
    let target
    if (props.multiple) {
      target = localValue.value.map((v) => v[v.length - 1])
    } else {
      target = value[localValue.value.length - 1]
    }
    emit('update:value', target, { selectedOptions })
    emit('change', target, { selectedOptions })
  }

  onMounted(() => getOptions())
</script>
